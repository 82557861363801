import { render, staticRenderFns } from "./orderMy.vue?vue&type=template&id=b76c8762&scoped=true&"
import script from "./orderMy.vue?vue&type=script&lang=js&"
export * from "./orderMy.vue?vue&type=script&lang=js&"
import style0 from "./orderMy.vue?vue&type=style&index=0&id=b76c8762&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b76c8762",
  null
  
)

export default component.exports